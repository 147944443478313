import React from "react";
import Footer from "@/components/footer";
import Layout from "@/components/layout";
import PageBanner from "@/components/page-banner";
import ServiceDetails from "@/components/service-details-website";
import CallToActionOne from "@/components/call-to-action-one";
import MenuContextProvider from "@/context/menu-context";
import SearchContextProvider from "@/context/search-context";
import HeaderOne from "@/components/header-one";
import SEO from "@/components/seo";

const WebsiteDevelopment = () => {
  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <SEO
          title="Soltriks - Webseitenentwicklung"
          description="Entdecken Sie Soltriks 'professionelle Website -Entwicklungsdienste.Wir sind darauf spezialisiert, robuste, skalierbare und sichere Websites zu erstellen, die auf die Bedürfnisse von Entwicklern und Unternehmen zugeschnitten sind."
        />
        <Layout PageTitle="Webseitenentwicklung">
          <HeaderOne />
          <PageBanner title="Webseitenentwicklung" name="Service" />
          <ServiceDetails />
          <CallToActionOne extraClassName="ready" />
          <Footer />
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  );
};

export default WebsiteDevelopment;
